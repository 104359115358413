import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import './css/reset.css'

import Header from './components/Header.js'
import Main from './components/Main.js'
import Visual from './components/Visualization.js'
import Graph from './components/Graph.js'
import Footer from './components/Footer.js';
import Admin from './components/Admin/Admin.js';
import AdminLogin from './components/Admin/AdminLogin.js';

function App() {
  return (
    <ThemeProvider theme={theme}>  {/* ThemeProvider로 전체를 래핑 */}
      <Router>
        <Header/>
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/" element={<Visual />} /> */}
          <Route path="/visual" element={<Visual />} />
          <Route path="/graph" element={<Graph />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
        <Footer/>
      </Router>
      </ThemeProvider>
  );
}

export default App;
