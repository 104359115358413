import { useRef, useEffect, useState } from 'react';
import { Chart, BarController, LineController, PieController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // 슬라이드 컴포넌트 임포트
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Main from './Main';
import { ChoroplethController, ProjectionScale, GeoFeature, ColorScale } from 'chartjs-chart-geo'; // ColorScale 추가
import * as ChartGeo from 'chartjs-chart-geo'; // ChartGeo 모듈 임포트

function GraphList() {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const sliderRef = useRef(null);

  // 그래프 타입을 관리하는 state
  const [chartType, setChartType] = useState('bar');

  const isMobile = window.innerWidth <= 768; // 768px 이하를 모바일로 간주
  const isMobile2 = window.innerWidth <= 500; // 500px 이하를 모바일로 간주

  const images = [
    {
      id: 1,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'bar',
      info: '막대'
    },
    {
      id: 2,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'line',
      info: '선'
    },
    {
      id: 3,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'pie',
      info: '원'
    },
    {
      id: 4,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'map',
      info: '지도' // 지도 슬라이드 추가
    }
  ];

  // Chart.js에서 필요한 모듈 등록
  Chart.register(BarController, LineController, PieController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement, ChoroplethController, ProjectionScale, GeoFeature, ColorScale); // ColorScale 등록

  // 그래프를 렌더링하는 useEffect
  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy(); // 기존 그래프 파괴
    }

    if (chartType === 'map') {
      // 세계지도 차트 생성
      const url = "https://unpkg.com/world-atlas@2.0.2/countries-50m.json"; // 세계지도 데이터를 불러오기 위한 URL
      fetch(url)
        .then(result => result.json())
        .then((datapoint) => {
          const countries = ChartGeo.topojson.feature(datapoint, datapoint.objects.countries).features;
          
          const data = {
            labels: countries.map(country => country.properties.name),
            datasets: [{
              label: 'Countries',
              data: countries.map(country => ({ feature: country, value: Math.random() * 100 }))
            }]
          };
          
          chartInstance.current = new Chart(ctx, {
            type: 'choropleth',
            data,
            options: {
              showOutline: true,
              showGraticule: true,
              scales: {
                projection: { // projection 설정
                  type: 'projection', // projection 타입 설정
                  position: 'bottom', // position 설정 추가
                  projection: 'equalEarth' // 지구본 모양 설정
                },
                color: { // color 스케일 추가
                  type: 'color', // 색상 스케일 타입
                  position: 'right', // 색상 스케일의 위치 설정
                  legend: {
                    position: 'bottom'
                  }
                }
              },
              plugins: {
                legend: {
                  display: false // 범례 숨기기
                }
              }
            }
          });
        });
    } else {
      // 일반 차트 생성
      chartInstance.current = new Chart(ctx, {
        type: chartType,
        data: {
          labels: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
          datasets: [
            {
              label: '65-74세',
              data: [80, 75, 5, 78, 82, 79, 81, 77, 80, 82],
              backgroundColor: chartType === 'pie' ? 'rgba(102, 204, 204, 0.8)' : 'rgba(102, 204, 204, 0.8)',
              borderColor: 'rgba(102, 204, 204, 1)',
              borderWidth: 1,
              fill: chartType === 'line' ? false : undefined
            },
            {
              label: '75세 이상',
              data: [45, 50, 47, 46, 48, 49, 47, 46, 48, 47],
              backgroundColor: chartType === 'pie' ? 'rgba(0, 102, 153, 0.8)' : 'rgba(0, 102, 153, 0.8)',
              borderColor: 'rgba(0, 102, 153, 1)',
              borderWidth: 1,
              fill: chartType === 'line' ? false : undefined
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: isMobile ? 1 : 2,
          scales: chartType === 'pie' ? {} : {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: '인구수(단위: 만 명)'
              },
              ticks: {
                stepSize: 20
              }
            },
            x: {
              title: {
                display: true,
                text: '년도'
              }
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            }
          }
        }
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // 메모리 누수 방지
      }
    };
  }, [chartType]);

  // 슬라이드 설정
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile2 ? 2 : 4,
    slidesToScroll: 1,
  };

  return (
    <div id='graphList'>
      <canvas id="myChart" ref={chartRef}></canvas> {/* ref 속성으로 캔버스를 참조 */}
      <div className='graphList'>
        <div className="slider-container">
          <Slider className='slider' ref={sliderRef} {...settings}>
            {images.map(image => (
              <div 
                className={`slide ${chartType === image.value ? 'active' : ''}`} // 선택된 그래프 타입일 경우 active 클래스 추가
                key={image.id} 
                onClick={() => setChartType(image.value)}
              >
                <img src={image.url} alt={`Slide ${image.id}`} />
                <span>{image.info}</span>
              </div>
            ))}
          </Slider>
        
          <button className="prev" onClick={() => sliderRef.current.slickPrev()}>
            <img src={process.env.PUBLIC_URL + '/img/dropdown.png'} alt="prev" />
          </button>
          <button className="next" onClick={() => sliderRef.current.slickNext()}>
            <img src={process.env.PUBLIC_URL + '/img/dropdown.png'} alt="next" />
          </button>
        </div>
        <p className='listBtn'><Link to={Main}>목록보기</Link></p>
      </div>
    </div>
  );
}

export default GraphList;














/* 지도 넣기 전 최종 백업본 */
// import { useRef, useEffect, useState } from 'react';
// import { Chart, BarController, LineController, PieController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick'; // 슬라이드 컴포넌트 임포트
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Main from './Main';

// function GraphList() {
//   const chartRef = useRef(null);
//   const chartInstance = useRef(null);
//   const sliderRef = useRef(null);

//   // 그래프 타입을 관리하는 state
//   const [chartType, setChartType] = useState('bar');

//   const isMobile = window.innerWidth <= 768; // 768px 이하를 모바일로 간주
//   const isMobile2 = window.innerWidth <= 500; // 768px 이하를 모바일로 간주

//   const images = [
//     {
//       id: 1,
//       url: process.env.PUBLIC_URL + "/img/circleMint.png",
//       value: 'bar',
//       info: '막대'
//     },
//     {
//       id: 2,
//       url: process.env.PUBLIC_URL + "/img/circleMint.png",
//       value: 'line',
//       info: '선'
//     },
//     {
//       id: 3,
//       url: process.env.PUBLIC_URL + "/img/circleMint.png",
//       value: 'pie',
//       info: '원'
//     },
//     {
//       id: 4,
//       url: process.env.PUBLIC_URL + "/img/circleMint.png",
//       value: 'bar',
//       info: '막대'
//     },
//   ];

//   // Chart.js에서 필요한 모듈 등록
//   Chart.register(BarController, LineController, PieController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement);

//   // 그래프를 렌더링하는 useEffect
//   useEffect(() => {
//     const ctx = chartRef.current.getContext('2d');

//     if (chartInstance.current) {
//       chartInstance.current.destroy(); // 기존 그래프 파괴
//     }

//     // 새로운 그래프 생성
//     chartInstance.current = new Chart(ctx, {
//       type: chartType, // 사용자가 선택한 타입으로 설정
//       data: {
//         labels: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
//         datasets: [
//           {
//             label: '65-74세',
//             data: [80, 75, 5, 78, 82, 79, 81, 77, 80, 82],
//             backgroundColor: chartType === 'pie' ? 'rgba(102, 204, 204, 0.8)' : 'rgba(102, 204, 204, 0.8)',
//             borderColor: 'rgba(102, 204, 204, 1)',
//             borderWidth: 1,
//             fill: chartType === 'line' ? false : undefined, // 라인 그래프일 때는 fill을 false로 설정
//           },
//           {
//             label: '75세 이상',
//             data: [45, 50, 47, 46, 48, 49, 47, 46, 48, 47],
//             backgroundColor: chartType === 'pie' ? 'rgba(0, 102, 153, 0.8)' : 'rgba(0, 102, 153, 0.8)',
//             borderColor: 'rgba(0, 102, 153, 1)',
//             borderWidth: 1,
//             fill: chartType === 'line' ? false : undefined, // 라인 그래프일 때는 fill을 false로 설정
//           }
//         ]
//       },
//       options: {
//         responsive: true, //반응형 크기 조정
//         maintainAspectRatio: true, // 비율유지
//         aspectRatio: isMobile ? 1 : 2, // 그래프 비율 가로가 2배
//         scales: chartType === 'pie' ? {} : {
//           y: {
//             beginAtZero: true,
//             title: {
//               display: true,
//               text: '인구수(단위: 만 명)'
//             },
//             ticks: {
//               stepSize: 20
//             }
//           },
//           x: {
//             title: {
//               display: true,
//               text: '년도'
//             }
//           }
//         },
//         plugins: {
//           legend: {
//             position: 'bottom',
//           },
//           tooltip: {
//             mode: 'index',
//             intersect: false,
//           }
//         }
//       }
//     });

//     return () => {
//       if (chartInstance.current) {
//         chartInstance.current.destroy(); // 메모리 누수 방지
//       }
//     };
//   }, [chartType]); // chartType이 변경될 때마다 그래프를 다시 렌더링

//   // 슬라이드 설정
//   const settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow:  isMobile2 ? 2 : 4,
//     slidesToScroll: 1,
//   };

//   return (
//     <div id='graphList'>
//       <canvas id="myChart" ref={chartRef}></canvas> {/* ref 속성으로 캔버스를 참조 */}
//       <div className='graphList'>
//         <div className="slider-container">
//         <Slider className='slider' ref={sliderRef} {...settings}>
//             {images.map(image => (
//               <div 
//                 className={`slide ${chartType === image.value ? 'active' : ''}`} // 선택된 그래프 타입일 경우 active 클래스 추가
//                 key={image.id} 
//                 onClick={() => setChartType(image.value)}
//               >
//                 <img src={image.url} alt={`Slide ${image.id}`} />
//                 <span>{image.info}</span>
//               </div>
//             ))}
//           </Slider>

        
//         <button className="prev" onClick={() => sliderRef.current.slickPrev()}>
//             <img src={process.env.PUBLIC_URL + '/img/dropdown.png'}></img>
//           </button>
//           <button className="next" onClick={() => sliderRef.current.slickNext()}>
//             <img src={process.env.PUBLIC_URL + '/img/dropdown.png'}></img>
//         </button>
//         </div>
//         <p className='listBtn'><Link to={Main}>목록보기</Link></p>
//       </div>
//     </div>
//   );
// }

// export default GraphList;
