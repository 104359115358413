import '../css/Graph.css';
import GraphList from './GraphList.js';
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main from './Main';

function Graph() {

  return (
    <div id='graph'>
      <div className='graphWrap'>
        <h2>통계청 장래인구추계 서울시 고령자 수</h2>
        <div className='dataWrap'>
          <p>피타그래프</p>
          <p>최근갱신일 <span>2024-09-02 19:33</span></p>
          <p>조회수 <span>15</span></p>
        </div>
        <div className='categoryWrap'>
          <p><img src={process.env.PUBLIC_URL + "/img/circleMint.png"}/>전국</p>
          <p><img src={process.env.PUBLIC_URL + "/img/circleMint.png"}/>보건,의료</p>
        </div>
        <GraphList/>
      </div>
      <div className="cellContainer">
        <ul>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>원천</p>
            <p className='cellInfo'>통계청</p>
          </li>
          <li>
            <p className='cellTitle'>그래프 설명</p>
            <p className='cellInfo'>서울시복지재단 연구논문 &lt; 인구구조 변화에 따른 복지분야 대응방안 연구&gt; 중에서 발췌<br/><br/><br/>
            &nbsp;&nbsp;&#183; 통계청 장래인구추계에 따르면, 서울특별시 65세 이상 인구는 2015년 121만명에서 지속적으로 증가하여 2035년 248만명 수준이 될 것으로 예상됨.<br/><br/>
            &nbsp;&nbsp;&#183; 65-74세 이상은 동기간 78만명 125만명으로, 75세 이상 인구는 43만명에서 122만명으로 증가할 것으로 예측됨.</p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Graph;
