import '../css/Header.css'
import { Link } from 'react-router-dom';
import Main from './Main';
import { useEffect, useRef, useState } from 'react';

function Header() {
  const [navActive, setNavActive] = useState(false); // 상태 관리
  const navRef = useRef(null);
  const msearchWrap = useRef(null);
  const headAnim = useRef();
  const moveY = useRef();
  const burgerCheck = useRef(); // burger-check에 접근하기 위한 ref 추가



  const msearch = function(isActive) {
    if (isActive) {
      msearchWrap.current.classList.add('active');
    } else {
      msearchWrap.current.classList.remove('active');
    }
  }

  useEffect(() => {
    // navActive가 true일 때 nav에 active 클래스를 추가
    if (navActive) {
      navRef.current.classList.add('active');
    } else {
      navRef.current.classList.remove('active');
    }
  }, [navActive]);
  

  return (
    <>
      <header>
        <h1><Link to={'/'}><img src={process.env.PUBLIC_URL + "/img/logo.png"}></img></Link></h1>
        <div className='rightWrap'>
          <div className="searchWrap">
            <input type="text" placeholder="키워드로 데이터를 검색해보세요."></input>
            <span>
              <img className='searchBtn' src={process.env.PUBLIC_URL + "/img/search.png"}></img>
            </span>
          </div>
          <div className='mSearchBtn'>
            <img className='searchBtn' onClick={() => msearchWrap.current.classList.toggle('active')} src={process.env.PUBLIC_URL + "/img/search.png"} />
          </div>
          <div className='nav'>
              <input className="burger-check" type="checkbox" id="burger-check" ref={burgerCheck} />
              <label className="burger-icon" htmlFor="burger-check" onClick={() => setNavActive((prev) => !prev)}>
                  <span className="burger-sticks"></span>
              </label>
          </div>
        </div>
        <div className="mSearchWrap" ref={msearchWrap}>
          <input type="text" placeholder="키워드로 데이터를 검색해보세요." />
          <span>
            <img src={process.env.PUBLIC_URL + "/img/search.png"} />
          </span>
        </div>
      </header>
      <nav ref={navRef} 
            onMouseLeave={() => setNavActive(false)}>
        <ul className='navWrap'>
          <li>
            <p onMouseEnter={() => setNavActive(true)}>
              <Link to={Main}>데이터 상품</Link>
            </p>
            <ul className='navContents'>
              <li><Link to={Main}>전체 상품</Link></li>
              <li><Link to={Main}>무료 상품</Link></li>
              <li><Link to={Main}>유료 상품</Link></li>
            </ul>
          </li>
          <li>
            <p onMouseEnter={() => setNavActive(true)}>
              <Link to={Main}>데이터 분석</Link>
            </p>
            <ul className='navContents'>
              <li><Link to={'Visual'}>시각화 분석</Link></li>
            </ul>
          </li>
          {/* <li>
            <p onMouseEnter={() => setNavActive(true)}>
              <Link to={Main}>데이터 활용</Link>
            </p>
            <ul className='navContents'>
              <li><Link to={Main}>분석 사례</Link></li>
              <li><Link to={Main}>활용 사례</Link></li>
            </ul>
          </li> */}
          <li>
            <p onMouseEnter={() => setNavActive(true)}>
              <Link to={Main}>플랫폼 이용 안내</Link>
            </p>
            <ul className='navContents'>
              <li><Link to={Main}>공지사항</Link></li>
              <li><Link to={Main}>FAQ</Link></li>
              <li><Link to={Main}>Q&A</Link></li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Header;
